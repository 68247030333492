<template>
	<Menu />
	<div class="text-lg lg:text-xl font-semibold px-4 text-rojo mt-8">
		Aviso de privacidad
	</div>
	<div class="space-y-4 px-4 py-6 mb-6"> 
		<p>Red Pepper Comunicación, S.A. de C.V. (en adelante Red Pepper), con domicilio en Calle Luz Saviñon número 13, Penthouse 4, Colonia Del Valle, Delegación Benito Juárez, C.P. 03100, México Distrito Federal, en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, hace de su conocimiento que sus datos personales, aun los sensibles, recabados o generados con motivo de la relación jurídica que tengamos celebrada, se celebre o haya concluido, se tratarán para identificación, operación, análisis, administración, ofrecimiento, promoción de productos y servicios, así como para cumplir con las obligaciones derivadas de dicha relación y para otros fines compatibles y análogos.</p>

		<p>De igual manera le informamos que los datos personales que recopilamos los utilizamos para ofrecerle otros productos y servicios, como un valor agregado, sin compartir su información con terceros anunciantes. Red Pepper conserva esta información personal solo por el tiempo que sea necesario para los fines descritos en la presente.</p>

		<p>Para limitar el uso y divulgación de sus datos, mantenemos políticas y procedimientos de seguridad y confidencialidad.</p>

		<p>En caso de que usted no esté de acuerdo con este aviso de privacidad, podrá ejercer su derecho de revocación por escrito o bien ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO). Para efectos de lo anterior, deberá elaborar su petición por escrito dirigido a Red Pepper, en el que se deberá indicar el nombre del Titular y su domicilio, la descripción clara y precisa de los datos respecto de los cuales desea ejercer alguna de las acciones antes mencionadas; acompañando copia de su identificación oficial con fotografía. Dicho escrito deberá ser presentado en las oficinas de Red Pepper ubicadas en Calle Luz Saviñon número 13, Penthouse 4, Colonia Del Valle, Delegación Benito Juárez, México Distrito Federal, en horario de 09:00 a 14:00 horas. Para cualquier duda o aclaración podrá comunicarse al teléfono (55) 17 39 06 37.</p>

		<p>El presente Aviso y sus correspondientes actualizaciones, estarán a su disposición en nuestras páginas de internet redpepper.com.mx y arieri.com.mx, a través de avisos colocados en nuestras oficinas, vía correo electrónico o por otros medios de comunicación que tengamos con usted.</p>
	</div>
</template>

<script>
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu
	},
	setup() {
		
	},
}
</script>